<template>
    <router-link :to="url" class="text-primary">
      {{ `${number}/${params.data.number.code}-AST/${params.data.number.month}/${params.data.number.year}` }}
    </router-link>
</template>

<script>
export default {
  name: 'CellRendererLetterDate',
  methods: {
  },
  computed: {
    encrypt() {
      let id = this.params.data.id;
      return this.$secure.encrypt(id);
    },

    url () {
      return { name: 'outgoing-letter-show',  params: { id: this.encrypt } };
    },

    number () {
      if (this.params.data.number.number.toString().length == 1) {
        return "00" + this.params.data.number.number;
      } else if (this.params.data.number.number.toString().length == 2) {
        return "0" + this.params.data.number.number;
      }

      return this.params.data.number.number;
    }
  }
}
</script>
