<template>
  <div class="flex items-center">
    <router-link :to="url" @click.stop.prevent class="text-inherit hover:text-primary">
      {{ params.value }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink',
  computed: {
    url () {
      return { name: 'employee-show', params: { id: this.$secure.encrypt(this.params.data.user.employee.nik) } }
    }
  },
  mounted() {
    // console.log(this.params.data);
  }

}
</script>
